/**
 * --------------------------------------------------------------
 * pengaturan umum
 * API_URL : for request data
 * CookiesName : cookies for customer
 * CookiesStaff : cookies for staff biar gak crash ketika dibuka 1 browser
 * --------------------------------------------------------------
 */
// export const baseChatURL = "http://localhost:8080";
// export const BASE_URL = "http://chat.restapi.localhost";

export const baseChatURL = "https://alpha.chat.rw.addin.my.id";
export const BASE_URL = "https://api.chat.rw.addin.my.id";
export const API_URL = BASE_URL + "/api";
export const IMAGE_URL = BASE_URL + "/files/images"; // jika beda base tinggal ganti saja
export const FILES_URL = BASE_URL + "/files/file";

/**
 * --------------------------------------------------------------
 * for name cookies
 * --------------------------------------------------------------
 */
export const CookiesClient = "rwClient";
export const CookiesStaff = "rwStaffCookies";

/**
 * --------------------------------------------------------------
 * images app
 * --------------------------------------------------------------
 */
export const image = {
  staff: "img/rw/staff.png",
  customer: "img/rw/customer.png",
  rwlogo: "img/rw/rw.png",
  bot: "img/rw/bot.png",
};

/**
 * --------------------------------------------------
 * konfigurasi untuk pilihan departemen
 * ketika akan register / mulai chat
 * id : id record nya didb
 * --------------------------------------------------
 */
export const departemenStatus = [
  {
    id: 1,
    name: "Sales",
    status: "Online",
  },
  {
    id: 2,
    name: "Billing",
    status: "Online",
  },
  {
    id: 3,
    name: "Technical Support",
    status: "Online",
  },
];

export const check_connection = false;
