import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

/**
 * --------------------------------------------------------------
 * just for local purpose
 * @project firebase addin-firebasee
 * --------------------------------------------------------------
 */
// let firebaseConfig = {
//   apiKey: "AIzaSyCwwY3VrPBX_utZHvF5i0ECw3NWJjaGhs8",
//   authDomain: "addin-firebasee.firebaseapp.com",
//   databaseURL: "https://addin-firebasee-default-rtdb.firebaseio.com",
//   projectId: "addin-firebasee",
//   storageBucket: "addin-firebasee.appspot.com",
//   messagingSenderId: "838966456921",
//   appId: "1:838966456921:web:1f18146e03fc2dc880b748",
//   measurementId: "G-22X5W179YS",
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyBAo6btsL5U-QPUI3K7d_TTcN7wtnPPxYk",
//   authDomain: "livechat-demo-3e391.firebaseapp.com",
//   databaseURL:
//     "https://livechat-demo-3e391-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "livechat-demo-3e391",
//   storageBucket: "livechat-demo-3e391.appspot.com",
//   messagingSenderId: "410333544045",
//   appId: "1:410333544045:web:d41b2913e9d0b0a47b4964",
// };
const firebaseConfig = {
  apiKey: "AIzaSyBLBx3_Fej3tLs192QlZTCp_T-1MKk3sQ0",
  authDomain: "rw-chat-demo.firebaseapp.com",
  databaseURL: "https://rw-chat-demo-default-rtdb.firebaseio.com",
  projectId: "rw-chat-demo",
  storageBucket: "rw-chat-demo.firebasestorage.app",
  messagingSenderId: "839407455246",
  appId: "1:839407455246:web:e6031acd2fda303d87805f",
};
firebase.initializeApp(firebaseConfig);

/**
 * --------------------------------------------------------------
 * init configures config
 * --------------------------------------------------------------
 */
const db = firebase.database();
const Auth = firebase.auth();

/**
 * --------------------------------------------------------------
 * export const
 * --------------------------------------------------------------
 */
export { db, Auth, firebase };
